import { Override } from "framer"

// Override function to colorize text between %%
export function FlashyText(props) {
    const { children } = props

    // Regular expression to find text between %%
    const regex = /%%(.*?)%%/g

    // Replace the text with the colored span
    const coloredText = children.replace(regex, (match, p1) => {
        return `<span style="color: #FF6D33;">${p1}</span>`
    })

    return <div dangerouslySetInnerHTML={{ __html: coloredText }} />
}
